<template>
    <div class="saleTable pumpingCost">
        <div class="table">
            <div class="radio-select">
                <div class="unified">
                    <el-radio @change="modifyPrice" v-model="radio" label="1">
                        统一泵送价格
                    </el-radio>
                    <el-input @change="modifyPrice" v-model="tybsjg" :disabled="radio == 1 ? false : true"></el-input>
                    <p>元/m³</p>
                </div>
                <el-radio @change="modifyPrice" v-model="radio" label="2">
                    泵送高度区间配置
                </el-radio>
            </div>
            <div class="shezhifang">
                <div class="table-bottom">
                    <div>
                        不足
                        <input
                            @change="modifyPrice"
                            v-model="pumpheight"
                            type="text"
                        >
                        方
                    </div>
                    <div>
                        收取
                        <input
                            @change="modifyPrice"
                            v-model="pumpprice"
                            type="text"
                        >
                        元
                    </div>
                </div>
                <div class="table-bottom">
                    <div>
                        不足
                        <input
                            @change="modifyPrice"
                            v-model="howManyFang"
                            type="text"
                        >
                        方
                    </div>
                    <div>
                        按
                        <input
                            @change="modifyPrice"
                            v-model="accordingToFang"
                            type="text"
                        >
                        方结算
                    </div>
                </div>
            </div>
            <div style="position:relative" class="table-div">
                <div class="zhezhao" v-if="radio == 1"></div>
                <div class="tableHeader">
                    <table>
                        <thead>
                            <tr>
                                <th style="width:0.8rem;">
                                    序号
                                </th>
                                <th style="width:4rem;">
                                    泵送高度
                                </th>
                                <th style="width:4rem;">
                                    泵送价格（元/m³）
                                </th>
                                <th style="width:1rem;">
                                    操作
                                    <el-button
                                        size="mini"
                                        class="iconfont header-add icontianjia1"
                                        @click="addToPumpingCost"
                                    >
                                    </el-button>
                                </th>
                                <!-- <th style="width:1rem">
                                </th> -->
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="tableBody">
                    <table>
                        <tbody>
                            <template v-for="(item, index) in tabPageData">
                                <tr :key="index">
                                    <td style="width:0.8rem;">
                                        {{ index+1 }}
                                    </td>
                                    <td style="width:4rem;">
                                        <el-select
                                            v-model="item.pump_lower"
                                            disabled
                                        >
                                            <el-option
                                                v-for="k in pumpingHeightSelectData"
                                                :key="k.code"
                                                :label="k.name"
                                                :value="k.name"
                                            >
                                            </el-option>
                                        </el-select>
                                        -
                                        <el-select
                                            v-model="item.pump_upper"
                                            placeholder=""
                                            :disabled="item.editObj.disabledSel"
                                            @change="preservePumpingCost(item, index)"
                                        >
                                            <el-option
                                                v-for="k in pumpingHeightSelectData"
                                                :key="k.code"
                                                :disabled="item.editObj.disabledArr.indexOf(k.name) === -1"
                                                :label="k.name"
                                                :value="k.name"
                                            >
                                            </el-option>
                                        </el-select>
                                    </td>
                                    <td style="width:4rem;">
                                        <input
                                            type="text"
                                            v-model="item.pump_price"
                                            @change="preservePumpingCost(item, index)"
                                        >
                                    </td>
                                    <td style="width:1rem;">
                                        <template>
                                            <el-button
                                                size="mini"
                                                type="danger"
                                                class="iconfont delete iconshanchu"
                                                v-if="index == tabPageData.length-1"
                                                @click="deletePumpingCost(item, index)"
                                            >
                                            </el-button>
                                        </template>
                                    </td>
                                    <!-- <td style="width:1rem"></td> -->
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'loadsh';
export default {
    // 销售价格管理-泵送费 
    name: 'pumping-cost',
    components: {},
    props: {
        currentData: {
            type: Object,
        },
        minHeight: {
            type: [Array, Object, String ],
        },
        isRelease: {
            type: [String, Number],
        },
    },
    data() {
        return {
            tybsjg: 0,
            radio: '2',
            deleteId: '',
            // 切换数据新ID
            newAreaid: '',
            listLength: 0,
            pumpheight: 0,
            pumpprice: 0,
            howManyFang: 0,
            accordingToFang: 0,
            // 泵送配置列表数据
            tabPageData: [],
            // 当前期数id
            price_id: '',
            // 当前区域id
            area_id: 0,
            // 泵送高度下拉数据
            pumpingHeightSelectData: [],
            isRegions: false,
            // 低泵送高度
            lowAltitude: '',
            // 高泵送高度
            pump_upper: '',
            // 统一泵送价
            unifiedPrize: 0,
            disabledBei: false,
            selectRows: {},
            // 是否发布
            isReleases: 0,
        };
    },
    watch: {
        currentData: {
            deep: true,
            immediate: true,
            handler: async function (newVal) {
                this.isRegions = newVal.isRegion;
                this.selectRows = newVal.selectRow;
                const initData = newVal.initData;
                if (newVal.isRegion) {
                    this.newAreaid = newVal.areaid;
                } else {
                    this.newAreaid = 0;
                }
                // 是否修改泵送费
                if (this.isRegions) {
                    if (newVal.selectRow.is_pump_base) {
                        this.radio = '1';
                    } else {
                        this.radio = '2';
                    }
                    this.tybsjg = newVal.selectRow.pump_base;
                    this.pumpheight = newVal.selectRow.pump_height;
                    this.pumpprice = newVal.selectRow.pump_price;
                    this.howManyFang = newVal.selectRow.pump_min;
                    this.accordingToFang = newVal.selectRow.pump_add;
                } else if (!this.isRegions) {
                    if (newVal.initData.SalePriceInfo.is_pump_base) {
                        this.radio = '1';

                    } else {
                        this.radio = '2';
                    }
                    this.tybsjg = newVal.initData.SalePriceInfo.pump_base;
                    this.pumpheight = newVal.initData.SalePriceInfo.pump_height;
                    this.pumpprice = newVal.initData.SalePriceInfo.pump_price;
                    this.howManyFang = newVal.initData.SalePriceInfo.pump_min;
                    this.accordingToFang = newVal.initData.SalePriceInfo.pump_add;
                }
                if (newVal.initData && initData.SalePriceInfo) {
                    if (initData.SalePriceInfo !== 'null' && initData.SalePriceInfo !== null) {
                        this.price_id = initData.SalePriceInfo.id;
                        if (initData.SalePriceInfo.is_area) {
                            if (initData.AreaList !== 'null' && initData.AreaList !== null) {
                                this.area_id = initData.AreaList[0].area_id;
                            }
                        } else {
                            this.area_id = 0;
                        }
                    }
                }

                // 获取泵送高度下拉数据
                this.pumpingHeightSelectData = await this.getpumpingHeight();

                // 获取升降标号列表数据
                this.getPumpingCost();
            },
        },
        tabPageData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if (newVal.length === 0) {return;}
                const len = newVal.length;
                this.tabPageData.forEach((item, index) => {
                    item.editObj = {
                        disabledArr: [],
                        disabledSel: true,
                    };
                    if (index === len - 1) {
                        item.editObj.disabledSel = false;
                    }
                    this.pumpingHeightSelectData.forEach(opt => {
                        if (opt.name >= item.pump_lower) {
                            item.editObj.disabledArr.push(opt.name);
                        }
                    });
                    if (item.pump_upper) {
                        const idx = _.findIndex(this.pumpingHeightSelectData, d => {
                            return Number(d.name) === Number(item.pump_upper);
                        });
                        item.pump_upper_code = idx ? this.pumpingHeightSelectData[idx].code : '';
                    }
                });
            },
        },
    },
    created() {
        // 期数id,区域id赋值
        if ((this.currentData.initData.SalePriceInfo || '') !== '') {
            this.price_id = this.currentData.initData.SalePriceInfo.id;
            if (this.currentData.initData.SalePriceInfo.is_area) {
                if ((this.currentData.initData.AreaList || '') !== '') {
                    this.area_id = this.currentData.initData.AreaList[0].area_id;
                }
            }
        }
    },
    mounted() {},
    computed: {},
    methods: {
        // 获取拉取泵送配置列表
        getPumpingCost(again) {
            this.tabPageData = [];
            let areaIds;
            if (this.isRegions) {
                areaIds = this.newAreaid ? this.newAreaid : this.area_id;
            } else {
                areaIds = 0;
            }
            if (this.currentData.historyId === '') {return;}
            this.$axios
                .get('/interfaceApi/sale/price_config/get_price_pump/' + this.currentData.historyId + '/' + areaIds)
                .then(res => {
                    if (res.length > 0) {
                        // tab传入页面数据--升降标号数据
                        this.tabPageData = res;
                        // 是否发布 未发布默认最小  已发布默认数据第一个
                        // eslint-disable-next-line max-len
                        this.tabPageData[0].pump_lower = this.isRelease === 0 || this.isRelease === '0' ? this.pumpingHeightSelectData[0].name : res[0].pump_lower;
                        // eslint-disable-next-line max-len
                        this.tabPageData[0].pump_lower_code = this.isRelease === 0 || this.isRelease === '0' ? this.pumpingHeightSelectData[0].code : res[0].pump_lower_code;
                    } else if (again !== 'again') {
                        this.tabPageData = [];
                        this.addToPumpingCost();
                    }
                    this.listLength = this.tabPageData.length;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 删除泵送高度
        deletePumpingCost(data, index) {
            this.deleteId = '';
            this.$axios
                .delete('/interfaceApi/sale/price_config/delete_price_pump/' + data.id, {
                })
                .then(res => {
                    this.$message({
                        message: res,
                        type: 'success',
                    });
                    this.tabPageData.splice(index, 1);
                    this.listLength = this.tabPageData.length;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加泵送费
        addToPumpingCost() {
            this.deleteId = '';
            if (this.tabPageData.length === 0) {
                this.tabPageData.push({
                    id: '',
                    pump_upper_code: '', // 泵送高度上限编码
                    pump_lower_code: this.pumpingHeightSelectData[0].code, // 泵送高度下限编码
                    price_id: this.price_id,
                    area_id: this.area_id,
                    pump_lower: this.pumpingHeightSelectData[0].name,
                    pump_price: 0,
                });
            } else {
                const lastObj = this.tabPageData.slice(-1)[0];
                // const lastList = this.pumpingHeightSelectData.slice(-1)[0];
                // if (lastObj.pump_upper && lastObj.pump_upper !== lastList.name) {
                const idx = _.findIndex(this.pumpingHeightSelectData, d => {
                    return d.name === lastObj.pump_upper;
                });
                this.tabPageData.push({
                    id: '',
                    pump_upper_code: '', // 泵送高度上限编码
                    pump_lower_code: this.pumpingHeightSelectData[idx + 1].code, // 泵送高度下限编码
                    price_id: this.price_id,
                    area_id: this.area_id,
                    pump_lower: this.pumpingHeightSelectData[idx + 1].name,
                    pump_price: 0,
                });
                // } else if (lastObj.pump_upper === lastList.name) {
                //     this.$message.warning('泵送高度已选择上限');
                // } else {
                //     this.$message.error('请选择泵送高度');
                // }
            }
        },
        // 更新配置信息
        modifyPrice() {
            let isPumpBase;
            if (this.radio === '1' || this.radio === 1) {
                isPumpBase = true;
            } else {
                isPumpBase = false;
            }
            this.selectRows.is_pump_base = isPumpBase;
            this.selectRows.pump_base = this.tybsjg;
            this.selectRows.pump_height = this.pumpheight;
            this.selectRows.pump_price = this.pumpprice;
            this.selectRows.pump_min = this.howManyFang;
            this.selectRows.pump_add = this.accordingToFang;
            this.$emit('modifyPrice', this.selectRows);
        },
        // 保存泵送费
        preservePumpingCost(data, index) {
            if (this.deleteId) {
                data.id = this.deleteId;
            }
            data.area_id = this.newAreaid;
            this.$axios
                .put('/interfaceApi/sale/price_config/modify_price_pump', data)
                .then(res => {
                    if (res) {
                        this.deleteId = '';
                        this.deleteId = res;
                        this.tabPageData[index].id = this.deleteId; // 修改
                        this.$message({
                            message: '修改成功',
                            type: 'success',
                        });
                        if (index < this.tabPageData.length - 1) {
                            const idx = _.findIndex(this.pumpingHeightSelectData, d => {
                                return d.name === data.pump_upper;
                            });
                            this.$set(this.tabPageData[index + 1], 'pump_lower', this.pumpingHeightSelectData[idx + 1].name);
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 环节退回状态请求
        getpumpingHeight() {
            const path = '/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=303-BS0&type=3';
            return this.$axios.$get(path, { defEx: true });
        },

    },
};
</script>
<style lang="stylus">
.pumpingCost
    .header-add
        border none
        background none
        padding: 0;
        width: 0.2rem;
        height: 0.2rem;
        color: #1577d2;
        border: 1px solid #1577d2;
        margin-top: 0.1rem;
        font-size: .16rem;
    .zhezhao
        position absolute
        left 0
        right 0
        top 0
        bottom 0
        background rgba(0,0,0,0.4);
        z-index 99
    .el-input__inner
        padding 0 !important
    .el-radio
        padding-left .2rem
    .radio-select
        display flex
        align-items center
        .unified
            display flex
            height .4rem
            align-items center
            font-size .16rem
            margin-right 1rem
            .el-radio
                margin-right .2rem
            .el-input
                width .6rem
            input
                width .5rem
                height .22rem
                text-align center
                margin-right .1rem
                border 1px solid #ccc
                border-radius .02rem
    .shezhifang
        display flex
        padding-bottom .1rem
        .table-bottom
            display flex
            font-size .16rem
            &:last-child
                margin-left 1rem
            input
                width .62rem
                height .22rem
                border 1px solid #ccc
                border-radius .02rem
                text-align center
            div:first-child
                margin-right .2rem
                margin-left .2rem

    .table
        width 100%
        height 100%
        border 1px solid #d7d7d7
        border-radius 2px
        overflow hidden
        display flex
        flex-direction column
        table
            width 13.7rem
            border-collapse collapse
            th, td
                font-size 0.14rem
                height .36rem
                line-height .36rem
        .tableHeader
            height 0.34rem
            background #edf0f5
            th
                color #022782
        .table-div
            flex 1
            display flex
            flex-direction column
            overflow-y auto
        .tableBody
            position relative
            overflow-y auto
            overflow-x hidden
            flex 1
            tr
                &:nth-of-type(even)
                    td
                        background #f8fafd
            td
                text-align center
                .el-select
                    width 1.5rem
                        .el-input__inner
                            box-sizing border-box
                            width 100%
                            height 0.22rem
                            line-height 0.22rem
                            font-size 0.16rem
                            margin-left 0
                            color #333
                            border 1px solid #e2ebf0
                            // padding 0 0.2rem 0 0.05rem
                            padding 0 !important
                            text-align center
                input
                    width: 0.82rem;
                    height: 0.22rem;
                    margin: 0 0.05rem;
                    font-size: 0.16rem;
                    color: #333;
                    text-align: center;
                    border: 1px solid #e2ebf0;
                button
                    border none
                    background none
                    padding 0 0.1rem
                    &.delete
                        color #D5000F
                    &.addTo
                        color #1577D2
</style>